<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="deleteSelectedTitle">
    <div class="modal-dialog modal-sm" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
            <div class="modal-header">
                <h2 class="modal-title" id="deleteSelectedTitle">
                    {{'deleteSelected' | i18n}}
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{'deleteSelectedItemsDesc' | i18n: cipherIds.length}}
            </div>
            <div class="modal-footer">
                <button appAutoFocus type="submit" class="btn btn-danger btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'delete' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
