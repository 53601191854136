<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="policiesEditTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="policiesEditTitle">{{'editPolicy' | i18n}} - {{name}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="loading">
                <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                <span class="sr-only">{{'loading' | i18n}}</span>
            </div>
            <div class="modal-body" *ngIf="!loading">
                <p class="text-muted">{{description}}</p>
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="enabled" [(ngModel)]="enabled"
                            name="Enabled">
                        <label class="form-check-label" for="enabled">{{'enabled' | i18n}}</label>
                    </div>
                </div>
                <ng-container *ngIf="type === policyType.MasterPassword">
                    <div class="form-group">
                        <label for="masterPassMinLength">{{'minLength' | i18n}}</label>
                        <input id="masterPassMinLength" class="form-control" type="number" name="MasterPassMinLength"
                            [(ngModel)]="masterPassMinLength">
                    </div>
                </ng-container>
                <ng-container *ngIf="type === policyType.PasswordGenerator">
                    <div class="form-group">
                        <label for="passGenMinLength">{{'minLength' | i18n}}</label>
                        <input id="passGenMinLength" class="form-control" type="number" name="PassGenMinLength"
                            [(ngModel)]="passGenMinLength">
                    </div>
                    <div class="form-group">
                        <label for="passGenMinNumbers">{{'minNumbers' | i18n}}</label>
                        <input id="passGenMinNumbers" class="form-control" type="number" name="PassGenMinNumbers"
                            [(ngModel)]="passGenMinNumbers">
                    </div>
                    <div class="form-group">
                        <label for="passGenMinSpecial">{{'minSpecial' | i18n}}</label>
                        <input id="passGenMinSpecial" class="form-control" type="number" name="PassGenMinSpecial"
                            [(ngModel)]="passGenMinSpecial">
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseNumbers"
                            [(ngModel)]="passGenUseNumbers" name="PassGenUseNumbers">
                        <label class="form-check-label" for="passGenUseNumbers">0-9</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseSpecial"
                            [(ngModel)]="passGenUseSpecial" name="PassGenUseSpecial">
                        <label class="form-check-label" for="passGenUseSpecial">!@#$%^&amp;*</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseLower"
                            [(ngModel)]="passGenUseLower" name="PassGenUseLower">
                        <label class="form-check-label" for="passGenUseLower">a-z</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="passGenUseUpper"
                            [(ngModel)]="passGenUseUpper" name="PassGenUseUpper">
                        <label class="form-check-label" for="passGenUseUpper">A-Z</label>
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary"
                    data-dismiss="modal">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>